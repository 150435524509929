var Popup, Marker; // Classes
var SharplaunchMap = {
    map: null,
    markers: [],
    bounds: null,
    map_center: null,
    active_marker: null,
    active_categories: ['0'],
    cat_colors: {},
    last_zIndex:10000,
    styles: bootstrap.map.styles || [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#c1d9e2"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f2f2f2"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#f6cf65"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2},{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]}],
    marker_path: 'M7.536,5.064c0,1.398-1.139,2.532-2.544,2.532c-1.405,0-2.544-1.134-2.544-2.532c0-1.398,1.139-2.532,2.544-2.532C6.397,2.532,7.536,3.666,7.536,5.064M10.176,5.064C10.176,2.267,7.897,0,5.088,0C2.278,0,0,2.267,0,5.064c0,1.23,0.441,2.357,1.173,3.234l3.919,5.628l3.976-5.71C9.211,8.039,9.342,7.852,9.46,7.654L9.5,7.597H9.493C9.927,6.852,10.176,5.987,10.176,5.064',
    loadScript: function() {

        var mapsapi = document.createElement("script");
        mapsapi.type = "text/javascript";
        mapsapi.src = "https://maps.google.com/maps/api/js?key=AIzaSyC5z50VGadxviwn6IlNfY0eKT7IUu22ffI&callback=SharplaunchMap.init";
        document.body.appendChild(mapsapi);

    },
    init: function() {

        var self = this;
        self.map_center = new google.maps.LatLng($('#map_canvas').attr('data-latitude'), $('#map_canvas').attr('data-longitude'));
        this.bounds = new google.maps.LatLngBounds();
        this.map = new google.maps.Map(document.getElementById('map_canvas'), {
            zoom: $('#map_canvas').data('zoom'),
            center: self.map_center,
            mapTypeControl: false,
            scrollwheel: false,
            scaleControl: false,
            streetViewControl: false,
            zoomControl: false,
            panControl: false,
            fullscreenControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: self.styles
        });

        // Map category colors
        $.each(bootstrap.poi_cats, function(k,v){
            self.cat_colors[v.id] = v.color;
        });


        /* Popup Class */
        Popup = function(index, data, position, content) {
            this.position = position;
            this.index = index;
            this.poi = data;
            this.type = 'logo';
            this.offset = {x:0 ,y:0 };
            this.content = content;
            this.content.classList.add('popup-bubble-content');
            var pixelOffset = document.createElement('div');
            pixelOffset.classList.add('popup-bubble-anchor');
            pixelOffset.appendChild(content);
            this.anchor = document.createElement('div');
            this.anchor.classList.add('popup-tip-anchor');
            this.anchor.appendChild(pixelOffset);
            this.stopEventPropagation();
            this.setMap(self.map);
            this.visible = true;
        };

        Popup.prototype = Object.create(google.maps.OverlayView.prototype);

        Popup.prototype.onAdd = function() {
            this.getPanes().floatPane.appendChild(this.anchor);
            var self = this;
            this.anchor.addEventListener('click',function(e){
                self.showTooltip()
            });
        };

        Popup.prototype.isVisible =  function(){ return this.visible; }

        Popup.prototype.getPosition =  function(){ return this.position; }

        Popup.prototype.hideTooltip = function(){
            $(this.content).addClass("shadow");
            $('#map-tooltip').removeClass("regular").removeClass("logo").addClass("hide");

        }

        Popup.prototype.showTooltip = function(){
            //$('.marker-bubble').addClass("shadow");
           // self.closeAllTooltips();
           
           // If it's main marker and it has no logo or name, it should show the address and 
           // don't allow expand
           if (this.poi.category == "" && this.poi.name == "") return;

            var that = this
            self.closeAllTooltips();
            self.active_marker = this.index;
            this.hide();
            self.last_zIndex++;
            this.anchor.style['zIndex'] = self.last_zIndex;

            var html = '<div class="tooltip-content"><h3>'+this.poi.name+'</h3><span>'+this.poi.address+'</span></div>';

            var logo_html = "";
            if (this.poi.logo_url){
                logo_html = '<img width="100" src="'+this.poi.logo_url+'">';
            }

            html = '<i class="icon close icon-close" style="display:none;cursor:pointer;position:absolute;right:4px;top:3px;color: #ddd;"></i>'
                    + logo_html 
                    + html;

            this.tooltipPosition();

            $('#map-tooltip').removeClass("regular").addClass("logo").html(html)
            .css({"width":"73px","height": "73px"})
            .removeClass('hide').stop().animate({
                    width: "335px",
                    padding: "10px",
                    height: "inherit",
                    "max-height": "135px",
                },350,function(){
                    $('.icon-close',this).fadeIn(50);
            });

            $('#map-tooltip .icon-close').click(function(){
                self.closeAllTooltips();
            });
        }
        Popup.prototype.tooltipPosition = function(){
            var that = this;
            var pos = that.getProjection().fromLatLngToContainerPixel(that.position);
            var left = pos.x - 30;
            var top = pos.y - 63;
            // set position
            $('#map-tooltip').css({
                "left":   left+'px',
                "top": top + 'px',
                "display": "block",

            });
        }

        Popup.prototype.draw = function() {
            var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            this.offset = divPosition;
            // Hide the popup when it is far out of view.
            var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';
            if (display === 'block') {
              this.anchor.style.left = parseInt(divPosition.x - 30) + 'px';
              this.anchor.style.top = parseInt(divPosition.y - 25) + 'px';
            }
            if (this.anchor.style.display !== display) {
              this.anchor.style.display = display;
            }

            if (this.poi.category == "0" || this.poi.category == ""){;
                let mainMArker = $(this.content);
                mainMArker[0].style.setProperty('--accent-color', '#'+self.cat_colors[this.poi.category]);
            }else{
                // Experimental border-bottom  for logos
                $(this.content).css("border-bottom","3px solid #"+self.cat_colors[this.poi.category]);
            }

        };

        Popup.prototype.stopEventPropagation = function() {
            var anchor = this.anchor;
            anchor.style.cursor = 'auto';

            ['click','dblclick', 'contextmenu', 'wheel', 'mousedown', 'touchstart',
            'pointerdown']
              .forEach(function(event) {
                anchor.addEventListener(event, function(e) {
                  e.stopPropagation();
                });
            });
        };

        Popup.prototype.show = function(){
            this.content.style.display = 'flex';
            this.visible = true;
        }

        Popup.prototype.hide = function(){
            this.content.style.display = 'none';
            this.visible = false;
            this.hideTooltip();
        }

        /* Marker Class */
        Marker = function(index,data){
            var that = this;
            this.index = index;
            this.visible = true;
            this.type = 'dot';
            this.position = new google.maps.LatLng(data.lat,data.lng)
            this.map = self.map;
            this.zIndex = 9999;
            this.poi = data;
            this.overlay = new google.maps.OverlayView();
            this.overlay.draw = function() {};
            this.overlay.setMap(this.map);
            this.icon = { // Building icon
                path: self.marker_path,
                fillOpacity: 1,
                fillColor: '#'+self.cat_colors[data.category],
                strokeWeight: 0,
                scale: 3,
                anchor: new google.maps.Point(5, 14)
            };

            if(typeof data.category != 'undefined' && data.category != 0) {
                this.icon.path = google.maps.SymbolPath.CIRCLE;
                this.icon.fillColor = '#'+(self.cat_colors[data.category] ? self.cat_colors[data.category] : bootstrap.color);
                this.icon.scale = bootstrap.map.icon_scale // Global map.icon_scale
                delete this.icon.anchor;
            }

            google.maps.Marker.call(this,this);
            self.bounds.extend(this.position);
            google.maps.event.addListener(this, 'click', function () {
                that.showTooltip()
            });
        }

        Marker.prototype = Object.create(google.maps.Marker.prototype);

        Marker.prototype.isVisible =  function(){ return this.visible; }

        Marker.prototype.showTooltip = function(){
            self.closeAllTooltips();
            self.active_marker = this.index;
            if (this.poi.name || this.poi.address ){
                var html = '<i class="icon close icon-close" style="cursor:pointer;position:absolute;right: 4px;    top: 3px;    color: #ddd;"></i><div class="tooltip-content"><h3>'+this.poi.name+'</h3><span>'+this.poi.address+'</span></div>';
                $('#map-tooltip').removeClass("logo").addClass("regular").html(html).removeClass("hide");
                 if (this.poi.category == "0" || this.poi.category == ""){
                    $('#map-tooltip').addClass("building");
                }
                var overlay = this.overlay;
                var proj = overlay.getProjection();
                var pos = proj.fromLatLngToContainerPixel(this.getPosition());
                var left = pos.x - 20;
                var top = pos.y + 22;

                $('#map-tooltip').css({ left: left, top: top });

                $('#map-tooltip .icon-close').click(function(){
                    self.closeAllTooltips();
                });
            }
        }
        Marker.prototype.tooltipPosition = function(){
            var overlay = this.overlay;
            var proj = overlay.getProjection();
            var pos = proj.fromLatLngToContainerPixel(this.getPosition());
            var left = pos.x - 20;
            var top = pos.y + 22;

            $('#map-tooltip').css({ left: left, top: top });
        }
        Marker.prototype.show = function(){
            this.setMap(self.map);
            this.visible = true;
        }

        Marker.prototype.hide = function(){
            this.setMap(null);
            this.visible = false;
            this.hideTooltip();
        }

        Marker.prototype.hideTooltip = function(){
            $('#map-tooltip').removeClass("regular").removeClass("logo").addClass("hide");
        };

        /****************** END classes definitions ************************/


        var main_markers = [];
        if(bootstrap.poi.length) {
            $.each(bootstrap.poi, function(k,v) {
                if (v.category == "0" || v.category == ""){
                    main_markers.push(v);
                    // self.placeMainMarker(v);
                } else {
                    self.placeMarker(v);
                }
            });

        } else {
            self.placeMarker({
                lat: $('#map_canvas').attr('data-latitude'),
                lng: $('#map_canvas').attr('data-longitude')
            });
        }
        
        // Render Building marker last, so it's on Z top
        if (main_markers.length){
            $.each(main_markers, function(k,building_marker) {
                self.placeMainMarker(building_marker);
            });
        } 

        if(bootstrap.poi_cats.length) {
            $.each(bootstrap.poi_cats, function(k,v) {
                if(v.id != '0') {
                    self.active_categories.push(v.id);
                }
            });
        }

        google.maps.event.addListener(this.map,'click',function(e) {
            self.closeAllTooltips();
        });

        google.maps.event.addListener(this.map,'zoom_changed',function() {
           self.closeAllTooltips();
        });

        google.maps.event.addListener(this.map,'center_changed',function() {
           // self.closeAllTooltips();
                self.tooltipPosition();
        });

        // enable controls
        this.mapControls();
    },
    tooltipPosition: function (){
        var self = this;
        if (self.active_marker != null){
            self.markers[self.active_marker].tooltipPosition();
        }
    },
    placeMarker: function(data) {
        var self = this;
        var location = new google.maps.LatLng(data.lat,data.lng)
        var index = self.markers.length;

        if (data.logo_url && data.logo_url != "" && bootstrap.map.show_logos_directly && data.category != "0"){
            var html  = '<div id="randombubblemarker-'+index+'" class="shadow marker-bubble"><img width="50" src="'+data.logo_url+'"></div>';
            $('body').append(html);
            popup = new Popup(index, data,
            location,
            document.getElementById('randombubblemarker-'+index));
            this.markers.push(popup);
        }else{
            marker = new Marker(index,data);
            this.markers.push(marker);
        }

    },
    // Merge this back to placeMarker with an IF statement
    placeMainMarker: function(data) {
        console.log('placeMainMarker', data);
        var self = this;
        var location = new google.maps.LatLng(data.lat,data.lng)
        var index = self.markers.length;

        data.is_property = true;

        var marker_content = data.name;

        // lower fontsize if text is too long and/or cut it
        if (marker_content.length > 23){
            marker_content = '<span style="font-size:12px">' + marker_content.substr(0, 35) + '</span>';
        }

        if (data.logo_url){
            marker_content = '<img width="50" src="'+data.logo_url+'">';
        }

        var html  = '<div id="randombubblemarker-'+index+'" class="shadow marker-bubble is-property bg-accent">' + marker_content + '</div>';

        // Change marker for M7 144
        if (window.bootstrap.client_id == 144) {
            html = '<div id="randombubblemarker-' + index + '" class="is-m7-main"><img width="40" height="50" src="/img/v2/clients/m7/map-marker.svg"></div>';
        }
        if (window.bootstrap.client_id == 160) {
            html = '<div id="randombubblemarker-' + index + '" class="is-r1-main"><img width="100" height="105" src="/img/v2/clients/r1/map-marker.png"></div>';
        }
        $('body').append(html);
        popup = new Popup(index, data,
        location,
        document.getElementById('randombubblemarker-'+index));
        this.markers.push(popup);

    },
    filterPoisByCategory: function() {
        var self = this;

        for (var i = 0; i < this.markers.length; i++ ) {
            if($.inArray(self.markers[i].poi.category, self.active_categories) == -1) {
                if(this.markers[i].poi.category != 0 && this.markers[i].isVisible()) {
                    this.markers[i].hide();
                }
            } else if (!this.markers[i].isVisible()){
                this.markers[i].show();
            }
        }
    },
    closeAllTooltips: function(){
        var self = this;
        self.active_marker = null;
        $('.marker-bubble').addClass("shadow");
        for (var i = 0; i < this.markers.length; i++ ) {
            if (!this.markers[i].isVisible() && (self.active_categories.indexOf(this.markers[i].poi.category) > -1 || this.markers[i].poi.category == "") )
            this.markers[i].show();
        }
        $('#map-tooltip').removeClass("building").removeClass("regular").removeClass("logo").addClass("hide");

    },
    mapControls: function() {
        var self = this;

//        self.map.fitBounds(self.bounds);

         $('#map-categories li').click(function(e){
            if(!$(this).hasClass('active')) {
                $(this).addClass('active');
                // add to active categories
                self.active_categories.push($(this).data('id'));
            } else {
                $(this).removeClass('active');

                // get index of class to be removed
                var index = self.active_categories.indexOf($(this).data('id'));
                if(index > -1) {
                    self.active_categories.splice(index, 1);
                }
            }
            self.filterPoisByCategory();
        });

        // init zoom control
        // if($('#zoom-slider').length) {
        //     var zoom_slider = $('#zoom-slider').slider({
        //         min: 10,
        //         max: 20,
        //         step: 1,
        //         range: 'min',
        //         value: $('#map_canvas').data('zoom'),
        //         slide: function(event, ui) {
        //             self.map.setZoom(ui.value);
        //         }
        //     });
        // }

        $('.zoom-control .zoom-in').click(function(){
            if(typeof zoom_slider != 'undefined') {
                var value = $('#zoom-slider').slider('value') + 1;
                // $('#zoom-slider').slider('value', value);
            } else {
                var value = self.map.getZoom() + 1;
            }
            self.map.setZoom(value)
        });
        $('.zoom-control .zoom-out').click(function(){
            if(typeof zoom_slider != 'undefined') {
                var value = $('#zoom-slider').slider('value') - 1;
                // $('#zoom-slider').slider('value', value);
            } else {
                var value = self.map.getZoom() - 1;
            }
            self.map.setZoom(value);
        });

        $('.map-type').click(function(){
            if ($(this).hasClass("open")){
                $(this).removeClass("open");
            }else{
                $(this).addClass("open");
            }
        })

        $('.map-type ul li').click(function(e){
            e.preventDefault();
            var type = $(this).find('a').attr("map-type");
            switch(type){
                case 'terrain':
                    type = google.maps.MapTypeId.TERRAIN;
                break;
                case 'default':
                    type = google.maps.MapTypeId.ROADMAP;
                break;
                case 'satellite':
                    type = google.maps.MapTypeId.SATELLITE;
                break;
            }
            self.map.setMapTypeId(type);
        });

        $('#enter-full-screen').click(function(e){
            e.preventDefault();
            if ($('body').hasClass("map-full-screen")){
                self.closeFullScreen();
                $('.zoom-control .button').removeClass("active");
            }else{
                $('.zoom-control #enter-full-screen').addClass("active");
                self.openFullScreen();
            }
        });

        $('#exit-full-screen').click(function(e){
            e.preventDefault();
            self.closeFullScreen();
        });

        $('.zoom-control .center-in').click(function(){
            self.map.setCenter(self.map_center);
            self.map.setZoom(12);
            $('#zoom-slider').slider('value', 15);
        });

        $('.view-legend').click(function() {
            if(!$(this).hasClass('active')) {
                $(this).text('Hide Legend').addClass('active');
                $('#map-categories').addClass('active');
            } else {
                $(this).text('View Legend').removeClass('active');
                $('#map-categories').removeClass('active');
            }
        });

        // set position
        /*
        $('.zoom-control').css({
            top: $('#location .section-title').position().top+$('#location .section-title').outerHeight()
        });
        */
        this.map.panBy(0,-Math.floor($('#location .section-title').outerHeight()/2));
    },
    openFullScreen: function(){
        $("#map_canvas").addClass("fullscreen");
        $('body').addClass("map-full-screen");
        $('#map-tooltip').css("position","fixed");
        setTimeout(function(){
            google.maps.event.trigger(self.map, 'resize');
        },500);

        return false;
    },
    closeFullScreen: function(){
        $('body').removeClass("map-full-screen");
        $("#map_canvas").removeClass("fullscreen");
        $('#map-tooltip').css("position","absolute");

        return false;
    }

}
$(document).ready(function(){
    if($('#map_canvas').length) {
        SharplaunchMap.loadScript();
    }
});

// Make it accessible on the global scope.
window.SharplaunchMap = SharplaunchMap;